import { assert, isNumber } from "../../util";
import { applyUpdate, walkExpression } from "./_internal";
const BIT_OPS = /* @__PURE__ */new Set(["and", "or", "xor"]);
const $bit = (obj, expr, arrayFilters = [], options = {}) => {
  return walkExpression(expr, arrayFilters, options, (val, node, queries) => {
    const op = Object.keys(val);
    assert(op.length === 1 && BIT_OPS.has(op[0]), `Invalid bit operator '${op[0]}'. Must be one of 'and', 'or', or 'xor'.`);
    return applyUpdate(obj, node, queries, (o, k) => {
      let n = o[k];
      const v = val[op[0]];
      if (n !== void 0 && !(isNumber(n) && isNumber(v))) return false;
      n = n || 0;
      switch (op[0]) {
        case "and":
          o[k] = n & v;
          break;
        case "or":
          o[k] = n | v;
          break;
        case "xor":
          o[k] = n ^ v;
          break;
      }
      return o[k] !== n;
    }, {
      buildGraph: true
    });
  });
};
export { $bit };