import {createAction, props} from "@ngrx/store";
import {IUserProfile} from "../../../app/interface/maestro-interface";

export const getUserProfile = createAction(
  '[Load App-main API] fetch user profile details'
);
export const onUserProfileFetchSuccess = createAction(
  '[Effects API] on user profile succuess API request',
  props<{
    details: IUserProfile
  }>()
);

export const onUserProfileFetchFailure = createAction(
  '[Effect API] on user profile failure API request',
);

export const checkInterconnectionAvailable = createAction(
  '[fetch API] on check internet connection',
  props<{
    isOnline: boolean
  }>(),
);

export const syncing = createAction(
  '[Effect sync] is syncing data',
  props<{
    isSyncing?: boolean
    msg: string,
    error?: Error,
    url?: string
  }>()
);

export const loadTransactionalData = createAction(
  '[Effects sync] load transactional data',
  props<{ server: true }>()
)

export const updateVerisonAvailability = createAction(
  '[Effects sync] update version update',
  props<{ update: boolean }>()
)
