import {createAction, props} from "@ngrx/store";
import {
  // Account,
  GatewayList,
  IAdditionalDineType,
  ICategoryData,
  ICounterData,
  IDeliveryCenterAsset,
  IGroupData,
  IGroupsOfKioskSettingsWithModuleSettings,
  IKioskTableFormSettings,
  IKisoskSettingsMetaData,
  ILocation,
  IPrinterStatus,
  IQuantityStatus,
  IReceiptTemplate,
  IStatus,
  IUserShift,
  KAgent,
  KioskStore,
  PosData,
  // RazorPay
} from "src/app/interface/maestro-interface";

export const currentBranch = createAction(
  "[Kiosk Store API] set current branch",
  props<{
    currentBranch: KioskStore | IDeliveryCenterAsset | ILocation
  }>()
);

export const loadBranchList = createAction(
  "[Kiosk Store API] load kiosk branch list",
);

export const loadBranchListSuccess = createAction(
  "[Kiosk Store API] load kiosk branch list success",
  props<{
    branchList: any
  }>()
);

export const loadBranchListFailure = createAction(
  "[Kiosk Store API] load kiosk branch list failure",
  props<{
    err: string
  }>()
);

export const reset = createAction(
  "[Kiosk Store API] reset kiosk",
);

export const getKioskSettingsByModule = createAction(
  '[Kiosk Module] get kiosk setting by module type',
  props<{
    settingsType: string,
    terminalId: string
  }>()
)

export const getKioskSettingsSuccess = createAction(
  '[Kiosk Module] get kiosk setting by success',
  props<{
    response: any
  }>()
)
export const getKioskSettingsFailure = createAction(
  '[Kiosk Module] get kiosk setting by failure',
  props<{
    error: any
  }>()
)


export const getCategories = createAction(
  "[Kiosk Store API] get categories",
  // props<{ branchID:string}>()
);

export const getCategoriesSuccess = createAction(
  "[Kiosk Store API] get categories success",
  props<{
    availableCategories: ICategoryData[]
  }>()
);

export const getCategoriesFailure = createAction(
  "[Kiosk Store API] get categories failure",
  props<{
    err: string
  }>()
);


export const incrementQtyViaProduct = createAction(
  "[Kiosk Store API] increment quantity via product",
  props<{
    product: any,
    isTable?: boolean
  }>()
);

export const updateTableCart = createAction(
  "[Kiosk Store API] update cart item for table",
  props<{
    prodId: string,
    isTable: boolean,
  }>()
);

export const getMaestroAreas = createAction(
  "[Maestro API] get maestro areas",
)

export const getMaestroAreasSuccess = createAction(
  "[Maestro API] get maestro areas success",
  props<{
    areas: any
  }>()
);

export const updateKitchenSentData = createAction(
  "[Kitchen Sent Data] update kitchen sent data",
  props<{
    kitchenData: any
  }>()
);


export const decrementQtyViaProduct = createAction(
  "[Kiosk Store API] decrement quantity via product",
  props<{
    product: any,
    isTable?: boolean
  }>()
);

export const filledStateWithTableData = createAction(
  "[Table Cart Sync] filling table in cart state",
  props<{
    tableData: any
  }>()
)


export const resetCartValues = createAction(
  "[Kiosk Store API] reset cart values",
);

export const removeCartItem = createAction(
  "[Kiosk Store API] remove cart item",
  props<{
    _id: string,
    isTable?: boolean
  }>()
);

export const updateCartItems = createAction(
  "[Kiosk Store API] update cart item",
  props<{
    products: any,
    isTable?: boolean
  }>()
);

export const quantityCheck = createAction(
  "[Kiosk Store API] quantity check",
  props<{
    productArray,
    storeId: string
  }>()
);
export const quantityCheckSuccess = createAction(
  "[Kiosk Store API] quantity check success",
  props<{
    quantityStatus: IQuantityStatus
  }>()
);
export const quantityCheckFailure = createAction(
  "[Kiosk Store API] quantity check failure",
  props<{
    err: string
  }>()
);

export const quantityCheckDataReset = createAction(
  "[Kiosk Store API] quantity check data reset",
);


export const getProductGroups = createAction(
  "[Kiosk Store API] get product groups",
);
export const getProductGroupsSuccess = createAction(
  "[Kiosk Store API] get product groups success",
  props<{
    productGroups: IGroupData[]
  }>()
);
export const getProductGroupsFailure = createAction(
  "[Kiosk Store API] get product groups failure",
  props<{
    err: string
  }>()
);
export const addStore = createAction(
  '[Kiosk Module] add Store sidebar open',
  props<{
    isAddStoreSidebarOpen: boolean
  }>()
)

export const addNewStoreForKioskUsingForm = createAction(
  '[kiosk module], add new store',
  props<{
    formData: unknown
  }>()
)

export const getAllPaymentGatewayList = createAction(
  '[Kiosk Module], get all payment gateway effect',
)

export const getAllPaymentGatewayListSucces = createAction(
  '[Kiosk Module], get all payment gateway success',
  props<{
    response: GatewayList[]
  }>()
)

export const sendPaymentVerification = createAction(
  '[Kiosk Moduel], send payement verification by admin',
  props<{
    orderId: string | number,
    Id?: unknown
  }>()
)
export const sendPaymentVerificationSucess = createAction(
  '[kiosk Module] payment verfication success',
  props<{
    res: unknown
  }>()
)
export const resetVerifcation = createAction(
  '[Kiosk Module] reset Verifcation'
)
export const printReceiptOnSok = createAction(
  '[KIOSK MODULE]  print reciept on sok printer',
  props<{
    orderId: string | number
  }>()
)


export const sendTrasactionProofImage = createAction(
  "[kiosk module] send transaction ",
  props<{
    payload: unknown,
    orderId: string
  }>()
)
export const sendTrasactionProofImageSuccess = createAction(
  "[kiosk module] send transaction success ",
  // props<{response: unknown}>()

)


export const getKioskSettingsMetadata = createAction('[KIOSK] get Kiosk Settings meta data api', props<{
  groupType: string
}>())

export const setKioskSettingsMetadata = createAction('[KIOSK] set Kiosk Setting meta data', props<{
  kioskMetaData: IKisoskSettingsMetaData,
  groupType: string
}>())

export const getAllGroupsForKioskSettings = createAction('[KIOSK] get all groups for kiosk')

export const getAllGroupsForKioskSettingsSuccess = createAction('[KIOSK] get all groups for kiosk success', props<{
  res: IGroupsOfKioskSettingsWithModuleSettings[]
}>())

export const putGenericKioskSettings = createAction('[KIOSK] set Kiosk Settings data api',
  props<{
    payload: IKioskTableFormSettings,
    moduleType: string
  }>())

export const putGenericKioskSettingsSuccess = createAction('[KIOSK] set Kiosk Settings data api success',
  props<{
    res: IKioskTableFormSettings,
    moduleType: string
  }>()
)
export const errorHandlerForGenericKioskSettings = createAction('[KIOSK] error handler Kiosk Settings data api')

export const resetProductLinkResponseType = createAction(
  '[Kiosk module] reset product link response type'
)

// export const getRazorpayDataSuccess = createAction(
//   "[Kiosk Store API] get razorpay config data success",
//   props<{
//     razorpay: RazorPay,
//     account: Account
//   }>()
// );

export const showLoaderForGenericSettings = createAction('[Generic] set loader action',
  props<{
    val: boolean
  }>()
)


export const removeTimeSlotsToSelectedProductsSuccess = createAction(
  '[Kiosk module] api call to update time slots to selected products time slot removal success'
);

export const getAllKagnets = createAction(
  '[Kiosk module] api call to get the all linked kAgents '
);

export const getAllKagnetsSuccess = createAction(
  '[Kiosk module] api call success for all linked kAgents ',
  props<{
    res: KAgent
  }>()
)

export const kioskAgentReboot = createAction(
  '[Kiosk module] api call for kiosk agent reboot',
  props<{
    machineId: string,
    rebootTo: string
  }>()
)

export const kioskAgentRebootSuccess = createAction(
  '[Kiosk module] api call for kiosk agent reboot success'
)

export const delinkKioskAgent = createAction(
  '[Kiosk module] api call for delink kiosk agent ',
  props<{
    kAgentId: string
  }>()
)

export const linkKioskAgentProcessSuccess = createAction(
  '[Kiosk module] api call for link process success ',
)

export const linkKioskAgentProcessFailure = createAction(
  '[Kiosk module] api call for link process failure ',
)

export const linkKioskAgent = createAction(
  '[Kiosk module] api call for linking kiosk agent ',
  props<{
    kAgentId: string,
    kioskId: string
  }>()
)

export const getAvailableKiosk = createAction(
  '[Kiosk module] api call for getting kiosk list in kAgent tab ',
)

export const getAvailableKioskSuccess = createAction(
  '[Kiosk module] api call for getting kiosk list in kAgent tab Success ',
  props<{
    kioksData: any
  }>()
)

export const changeTheMachinename = createAction(
  '[Kiosk module] api call for changing the machine name',
  props<{
    kAgentId: string,
    machineName: string
  }>()
)

export const changeTheMachinenameSuccess = createAction(
  '[Kiosk module] api call for changing the machine name success',
)

export const updateLiveStatusOfKAgent = createAction(
  '[Kiosk module] update live status of kAgent',
  props<{
    machineId: string,
    lastStatusUpdate: string
  }>()
)

export const getMaestroInfo = createAction(
  '[POS Metainfo ] get pos meta info'
);


export const getMaestroInfoSuccess = createAction(
  '[POS Metainfo ] get pos meta success',
  props<{
    maestroInfo: PosData
  }>()
)

export const getMaestroTable = createAction(
  '[POS TABLE ] get pos ', props<{
    areaId?: string,
    status?: string,
    remote?: boolean
  }>()
);


export const getMaestroTableSuccess = createAction(
  '[POS TABLE ] get pos Success',
  props<{
    maestroTable: unknown
  }>()
)


export const kAgentTabAPiFailure = createAction(
  '[Kiosk module] action for any api fail in k-agent tab',
)
export const getLayoutKioskOrderPage = createAction(
  '[Kiosk module] action for getting layout for kiosk order page',
)
export const getLayoutKioskOrderPageSuccess = createAction(
  '[Kiosk module] success action for getting layout for kiosk order page',
  props<{
    response: any
  }>()
)

export const getKioskKAgentMetadata = createAction('[KIOSK] get Kiosk K-agent meta data api', props<{
  module: string,
  typeId: string
}>())

export const setKioskKAgentMetadata = createAction('[KIOSK] set K-agent Setting meta data', props<{
  kioskKAgentMetaData: IKisoskSettingsMetaData,
  typeId: string
}>())

export const checkIfUserShiftInProgress = createAction(
  '[Kiosk Store API] action for checking if user shift in progress',
)

export const checkIfUserShiftInProgressSuccess = createAction(
  '[Kiosk module] action for checking if user shift in progress success',
  props<{
    status: boolean,
    created: string
  }>()
)

export const getUserAllShift = createAction(
  '[Kiosk Store API] action for getting all shifts',
  props<{
    startDate: number,
    endDate: number
  }>()
)

export const getUserAllShiftSucess = createAction(
  '[Kiosk Store API] action for getting all shifts success',
  props<{
    shifts: IUserShift[]
  }>()
)

export const addUserShift = createAction(
  '[Kiosk Store API] action to add user shift',
)

export const endUserShift = createAction(
  '[Kiosk Store API] action to end user shift',
)

export const getReceiptTemplate = createAction(
  '[Kiosk Store API] get receipt template',
  props<{
    posId: string
  }>()
)
export const getReceiptTemplateSuccess = createAction(
  '[Kiosk Store API] get reciept template success',
  props<{
    template: IReceiptTemplate
  }>()
)
export const updateSyncDbUrl = createAction(
  '[Kiosk Store] update sync db url',
  props<{ url: string, userId: string, userPassword: string }>()
)

export const setPrinterStatus = createAction(
  '[Kiosk Store] set printer status',
  props<{ status?: Record<string, IStatus>, event?: string }>()
)
export const updatePrinterStatus = createAction(
  '[Kiosk Store ] update printer status',
  props<{ status?: IPrinterStatus }>()
)
export const setPrinterOffline = createAction(
  '[Kiosk Store ] set printer offline on error',
)
export const setSwitchTable = createAction(
  '[Kiosk Store ] set switch table value',
  props<{ isswitchTable?: boolean }>()
)

export const setAdditionalDineType = createAction(
  '[Kiosk Store] get additional other services dine types',
  props<{ data: IAdditionalDineType }>()
)
export const loadCounterData = createAction(
  '[Kiosk Store API] load counter list data'
);
export const loadCounterDataSuccess = createAction(
  '[Kiosk Store API] load counter list data success',
  props<{ data: ICounterData[] }>()
);