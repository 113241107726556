/**
 * Created by chandru on 11/5/20.
 */
import pkg from '../../package.json';


export const WW = {
  E_CONNECTIVITY: "connectivity",
  E_PRINTER_STATUS: "printerStatus",
  E_PRINT: "print",
  E_RXDB_INIT: "initRxdb"
}

export const COUCHDB = {
  USER: 'admin',
  PASS: 'admin'
}

export const ORDER = {
  PLACED: 'placed',
  CREATED: 'created',
  DISCARDED: 'discarded',
  UPDATED: 'updated',
  DELETED: 'deleted',
}

export const T_EVENT = {
  REPRINT: 'Re-Print',
  LOGIN: 'Login'
}

export const PAYMENT = {
  PAID: 'paid',
  NOT_PAID: 'not paid',
}

export const TABLE_STATUS = {
  AVAILABLE: "available",
  OCCUPIED: "occupied",
  RECEIPT_GEN: "recieptGenerated"
}

export const MODE = {
  OFFLINE: 'offline',
  ONLINE: 'online',
  SHADOW: 'shadow'
}

export const PRINT = {
  T_ORDER_REC: 'tableOrderReciept',
  T_ON_TABLE_PAY: 'tableOrderInvoice',
  T_ON_TABLE_SO_UPDATE: 'tableOrderCancelledKitchenToken',
  T_ON_TABLE_SEND_KITCHEN: 'tableOrderKitchenToken',
  T_PRODUCT_SALES_REPORT: 'productSaleReport',
  T_PRODUCT_SALES_REPORT_BY_DINETYPE: 'productSaleReportByDineType',
  TYPE: {
    K_AGENT: 'kAgent',
    EPSON: 'epson',
    USB: 'local',
    I_MIN: 'imin',
    ZEN_HB: 'zenBridge'
  }
}

export const DB = {
  POS_TABLES: "maestroTables",
  POS_TABLE: "maestroTable",
  POS_INFO: "maestroInfo",
  POS_AREA: "maestroAreas",
  POS_TEMPLATE: "receiptTemplate",
  POS_OTHER: "otherServices",
  POS_SHIFT: 'shift',
  POS_STORE_LIST: "storeList",
  POS_CAT_PRODUCTS: "categoriesAndProducts",
  POS_ORDER: "orders",
  SETTINGS: "settings",
  POS_LAYOUT: "maestroLayout",
  POS_SWITCH_TABLE: "switchTables",
  POS_PAYMENT_REC: "paymentRecieved",
  TAX: 'tax',
  POS_OPERATORS: 'operators'
}

export const DINETYPE = {
  DINE_IN: "dineIn",
  DINE_OUT: "dineOut",
  TABLE: "table",
  OTHER_SERVICES: "otherServices",
}


export const CONSTANT = {
  SOCKET: {
    KIOSK: {
      NAMESPACE: {
        ORDER: '/orderToDelivery/order/kiosk',
      },
      TOPICS: {
        LIVE: 'live',
        LIVE_STATUS: 'live_status'
      }
    },
    POS: {
      NAMESPACE: {
        POSSTORE: '/iotzen/posStore/',
        POSSETIINGS: '/kiosk/account/',
        POSPAYMENT: '/iotzen/payment/pos/'
      },
      TOPICNAME: {
        POSSTORE: 'productUpdate',
        POSSETTINGS: 'settings',
        POSPAYMENT: 'paymentUpdate'
      },
      KEY: {
        POSSTORE: 'kiosk-order-pos-store',
        POSSETTINGS: 'kiosk-order-pos-setting',
        POSPAYMENT: 'kiosk-order-pos-payment'
      }
    }
  },
  TABLE_SESSION_NAME: {
    KIOSK: {
      ORDER: 'kiosk_order_tab',
      TODAY: 'kiosk_today_order_tab',
      CDS: 'kiosk_cds_tab',
      KDS: 'kiosk_kds_tab',
      PRODUCT: 'kiosk_product_management'
    },
  },
  MODULES: {
    KIOSK: {
      MODULE: 'kiosk'
    }
  },
  TIMEINTERVALS: {
    OFFLINEINMIN: 3
  },

  // SENTRY_KEY: 'https://2d9c47fa38254b948e35f0615b842e62@o219463.ingest.sentry.io/1363394'
  SENTRY_KEY: 'https://568f9257460747fa9eee1ce21d91f927@o1302573.ingest.sentry.io/6549681',

  KIOSK: {
    STORE_lOCATION: 'KIOSK_STORE_LOCATION',
    REQUIRED_ORDER_FIELDS: {
      TABLE: 'orderId,created,customerDetails.firstName,customerDetails.lastName,customerDetails.location,customerDetails.firstName,customerDetails.mobileNumber,expectedDeliveryDateTime,expectedDeliveryDateTimeDetails,orderDetails.items.name,orderDetails.items.quantity,orderDetails.items.instruction,billAddress.phone,shippingAddress.phone,accountId,pickUpLocation.name,pickUpLocation.city,pickUpCity.city,logisticProvider.name,orderSource,ordertype,delivery,orderStatus,isAddedToQueue,orderCancelled,payment,paymentGateway,pickup',
    }
  },
  VERSION: pkg.version,
  SENTRY_AUTH_TOKEN: 'aa592018818548b2837a832f1df793f1f1f69a196f3a49f0849a813f2fd00dd2'
};

export const POS = {
  ORDER_SOURCE: 'iotzen-pos'
};
