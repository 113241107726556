{
  "name": "iotzen-ui-maestro",
  "version": "9.0.7-test",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0",
    "build": "ng build --configuration dev ",
    "watch": "ng build --watch --configuration development",
    "test": "ng add @angular/pwa",
    "lint": "ng lint --fix",
    "web-worker": "ng generate web-worker app",
    "update": "ng update @angular/core@18 @angular/cli@18",
    "cf": "ng generate @angular/core:control-flow",
    "standlonw": "ng generate @angular/core:standalone",
    "state-migration": "ng update @ngrx/store@18",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org zendynamix --project zenpos ./dist && sentry-cli sourcemaps upload --org zendynamix --project zenpos ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.11",
    "@angular/common": "^18.2.11",
    "@angular/compiler": "^18.2.11",
    "@angular/core": "^18.2.11",
    "@angular/forms": "^18.2.11",
    "@angular/platform-browser": "^18.2.11",
    "@angular/platform-browser-dynamic": "^18.2.11",
    "@angular/router": "^18.2.11",
    "@angular/service-worker": "^18.2.11",
    "@casl/ability": "^6.7.2",
    "@casl/angular": "^8.2.8",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngx-translate/core": "^15.0.0",
    "@sentry/angular": "^8.37.1",
    "@zdIOTPlatform/ng-iot-printers": "^1.2.4",
    "@zdIOTPlatform/zen-tax": "^1.0.16",
    "bcryptjs": "^2.4.3",
    "crypto-browserify": "^3.12.1",
    "lodash": "^4.17.21",
    "lokijs": "^1.5.12",
    "moment-timezone": "^0.5.46",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "rxdb": "^15.37.0",
    "rxjs": "~7.8.1",
    "simple-keyboard": "^3.8.14",
    "simple-keyboard-layouts": "^3.4.39",
    "tslib": "^2.8.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-eslint/schematics": "^18.4.0",
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.11",
    "@ngrx/store-devtools": "^18.1.1",
    "@sentry/cli": "^2.38.2",
    "@types/bcryptjs": "^2.4.6",
    "@types/jasmine": "~5.1.4",
    "@types/lodash": "^4.17.12",
    "@types/node": "^22.7.8",
    "@types/w3c-web-usb": "^1.0.10",
    "angular-eslint": "^18.4.0",
    "eslint": "^9.7.0",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.5.4",
    "typescript-eslint": "^8.0.0-alpha.44"
  },
  "browser": {
    "fs": false
  }
}
