import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';

export const selectBranch = (state: AppState) => state.kiosk;

export const getBranchList = createSelector(
  selectBranch,
  (kiosk) => kiosk.branchList
);

export const getKioskSettings = createSelector(
  selectBranch,
  (kiosk) => kiosk.kioskSettings
);

export const getAvailableCategories = createSelector(
  selectBranch,
  (kiosk) => kiosk.availableCategories
);

export const getAvailableProducts = createSelector(
  selectBranch,
  (kiosk) => kiosk.availableProducts
);

export const getCartItems = createSelector(
  selectBranch,
  (kiosk) => kiosk.cartItems
);

export const getCartLength = createSelector(
  selectBranch,
  (kiosk) => kiosk.cartLen
);

export const getQuantityStatus = createSelector(
  selectBranch,
  (kiosk) => kiosk.quantityStatus
);

export const verificationProcess = createSelector(
  selectBranch,
  (kiosk) => kiosk.verificationProcess
);

export const getAllKioskSettingsMetaData = createSelector(
  selectBranch,
  (kiosk) => kiosk.kioskSettingsMetaData
);

export const getMaestroAreas = createSelector(
  selectBranch,
  (kiosk) => kiosk.maestroAreas
);

export const getKitchenSentData = createSelector(
  selectBranch,
  (kiosk) => kiosk.kitchenSentData
);

export const getMaestroInfoData = createSelector(
  selectBranch,
  (kiosk) => kiosk.maestroData
);

export const printerStatus = createSelector(
  selectBranch,
  (kiosk) => kiosk.printerStatus
);

export const getProductPricing = (state: AppState) =>
  state.kiosk.kioskSettings?.['productPricing'];
export const syncDb = (state: AppState) =>
  state.kiosk.kioskSettings?.['syncdb'];

export const tables = (state: AppState) => state.kiosk.maestroTable;
export const getUserShiftProgressStatus = createSelector(
  selectBranch,
  (kiosk) => kiosk['userShift'].status
);
export const getUserDetailsShiftProgress = createSelector(
  selectBranch,
  (kiosk) => kiosk['userShift'].currentShiftData
);
export const getUserShiftList = createSelector(
  selectBranch,
  (kiosk) => kiosk['userShift'].allShifts
);
export const getReceiptTemplateSelector = createSelector(
  selectBranch,
  (kiosk) => kiosk['receiptTemplate']
);

export const parcelCharge = createSelector(
  selectBranch,
  (kiosk) => kiosk?.kioskSettings?.parcelCharge
);

export const catInfoByProductId = (pId: string) =>
  createSelector(selectBranch, (kiosk) => {
    const c = kiosk?.availableCategories?.find((c) =>
      c.products.find((x) => x.productInfo._id === pId)
    );
    return c.categoryInfo;
  });

export const getIsSwitchTable = createSelector(
  selectBranch,
  (kiosk) => kiosk?.isSwitchTable
);

export const getCatNameFromId = (cId: string | string[]) =>
  createSelector(selectBranch, (kiosk) => {
    if (Array.isArray(cId)) {
      return kiosk.availableCategories
        ?.map((c) => {
          if (cId.includes(c?.categoryInfo?._id)) {
            return c?.categoryInfo?.name;
          }
        })
        .filter((x) => !!x);
    } else {
      return kiosk.availableCategories?.find(
        (c) => c?.categoryInfo?._id === cId
      )?.categoryInfo?.name;
    }
  });

export const printConfig = createSelector(
  selectBranch,
  (kiosk) => kiosk?.kioskSettings?.printConfig
);
export const additionalDinetype = createSelector(
  selectBranch,
  (kiosk) => kiosk?.additionalDinetype
);

export const getPrinterData = createSelector(
  selectBranch,
  (kiosk) => kiosk?.kioskSettings?.printerData
);
